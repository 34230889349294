const moduleName = "department";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepartments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchModuleDepartements(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName,
            {
              params: queryParams,
            }
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    fetchModule(ctx, { id }) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName + `/${id}`
          )
          .then((response) => {
            // boolean conversions
            const data_to_update = response.data;
            resolve(data_to_update);
          })
          .catch((error) => reject(error));
      });
    },
    fetchModuleRequirement(ctx, { id }) {
      return new Promise((resolve, reject) => {
        myApi
          .get(moduleName + `/${id}`
          )
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },
    addModule(ctx, moduleData) {
      return new Promise((resolve, reject) => {
        myApi
          .post(moduleName, {
            moduleName: moduleData,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteModule(ctx, id) {
      return new Promise((resolve, reject) => {
        myApi
          .delete(moduleName + '/' + id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
